@import '/styles/base.scss';

.videoWrapper {
  aspect-ratio: 16/9;
  object-fit: cover;
  position: relative;
  width: 100%;
  height: 100%;

  iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  &:not(.videoWrapperIsPreview) {
    max-height: 100%;
    width: 100%;
  }
}

.videoWrapperIsPreview iframe {
  pointer-events: none;
}

.videoWrapperInAccordion {
  position: absolute;
  top: 50%;
  left: 50%;
  height: 100%;
  transform: translate3d(-50%, -50%, 0);
  width: unset;
}
