@import '/styles/base.scss';

.accordionTrigger {
  @include focus(-0.4rem);
  position: relative;
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: flex-start;
  padding: 2.4rem 2.4rem 4rem;
  color: var(--theme-copy);
  cursor: pointer;
  transition: color 0.3s ease;

  @include lg {
    padding: 4rem 4rem 4.8rem;
  }

  &:hover:not(:disabled) {
    color: rgba(var(--theme-copy-rgb), 0.65);
    .iconWrapper {
      color: rgba(var(--theme-copy-rgb), 0.65);
      transform: rotate(45deg);
      transform-origin: center center;
    }
  }
}

.icon {
  width: 1.6rem;
  height: 1.6rem;
}

.iconWrapper {
  display: flex;
  margin-bottom: 0.4rem;
  color: var(--theme-copy);
  transition:
    color 0.3s ease,
    transform 0.5s ease;

  @include md {
    margin-bottom: 0.8rem;
  }
}

.content {
  display: flex;
  flex-direction: column;
  align-items: baseline;
  text-align: start;
}

.titleLabel {
  font-family: $font-halyard-text-medium;
  font-weight: 500;
}

.granteeLabel {
  display: block;
  margin-top: 0.4rem;

  @include md {
    display: none;
  }
}

@keyframes iconRotation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(45deg);
  }
}
