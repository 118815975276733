@import '/styles/base.scss';

.modal {
  position: fixed;
  inset: 0;
  background-color: $color-black;
  z-index: $z-400;

  & > .button {
    position: absolute;
    z-index: 2;
    top: 2rem;
    right: 2rem;
    background-color: $color-black;
    color: white;
  }
}

.icon {
  width: 1.8rem;
  height: 1.8rem;
}
