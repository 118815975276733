@import '/styles/base.scss';
@import './grantActivity.constants.module.scss';
@import '~/v1/components/navigation/navigation.constants.module.scss';

.accordion {
  position: relative;
  display: flex;
  overflow: hidden;
  width: 100%;
  background-color: var(--theme-primary);

  @include md {
    min-height: $min-height;
    max-height: calc(#{$max-height} - #{$primary-sticky-tablet-height});
  }
  @include lg {
    min-height: $min-height;
    max-height: $max-height;
  }

  @include md {
    display: flex;
  }
}

// this is the aspect ratio of the expanded content, the width is set with inline style
// we use it to give the accordion proper height since the content has absolute position
.placeholderCard {
  aspect-ratio: $expanded-content-aspect-ratio;
  opacity: 0;
}

.accordionInner {
  width: 100%;

  @include md {
    position: absolute;
    display: flex;
    height: 100%;
  }
}

.accordionItem {
  &::before {
    position: absolute;
    display: block;
    width: 100%;
    height: 100%;
    content: '';
  }
}

// used to lighten the underlay color and prevent it from being seethrough
.accordionItem-dark {
  &::before {
    background-color: $color-white;
  }
}

// used to darken the underlay color and prevent it from being seethrough
.accordionItem-light {
  &::before {
    background-color: $color-black;
  }
}

.colorUnderlay {
  position: absolute;
  width: inherit;
  height: 100%;
}

@for $i from 0 through 4 {
  .colorUnderlay-#{$i} {
    background-color: rgba(var(--theme-primary-rgb), #{1 - ($i + 1) * 0.05});
  }
}

.buttonWrapper {
  position: relative;
  overflow: hidden;
  @include md {
    position: absolute;
    display: flex;
    height: 100%;
  }
}

.contentWrapper {
  position: relative;
  overflow: hidden;
  @include md {
    position: absolute;
  }
}
