@import '/styles/base.scss';
@import '../grantActivity.constants.module.scss';
@import '~/v1/components/navigation/navigation.constants.module.scss';

.card {
  position: relative;
  display: grid;
  align-items: flex-end;

  // Specificity!
  & > .button {
    position: absolute;
    z-index: 1;

    right: 2rem;
    bottom: 2rem;
    width: 4.4rem;
    height: 4.4rem;

    border: 0.1rem solid rgba($color-white, 0.25);
    @include md {
      right: 2.4rem;
      bottom: 2.4rem;
    }
    @include lg {
      right: 4rem;
      bottom: 4rem;
    }
    &:hover {
      border: 0.2rem solid rgba($color-white, 0.25);
    }
  }
}

.cardInner {
  grid-column: 1;
  grid-row: 1;
}

.card,
.cardInner {
  width: 100%;

  height: auto;
  max-height: calc(100vh - #{$primary-sticky-mobile-height} - 106px);
  @include md {
    height: 100%;
    max-height: none;
  }
}

.cardInner {
  overflow: hidden;
  aspect-ratio: $expanded-content-aspect-ratio;
}

.image {
  width: 100%;
  height: 100%;
  img {
    object-fit: cover;
  }
}

.cardContent {
  z-index: 1;
  display: flex;
  width: 100%;
  height: min-content;
  box-sizing: border-box;
  flex: 1;
  flex-direction: column;
  justify-content: space-between;
  padding: 27.5rem 2rem 4rem;
  background: linear-gradient(to top, rgba(0, 0, 0, 0.8), transparent);
  color: white;
  grid-column: 1;
  grid-row: 1;

  @include md {
    padding: 27.5rem 2.4rem 4rem;
  }

  @include lg {
    padding: 27.5rem 4rem 4.8rem;
  }
}

.title {
  margin-bottom: 2.4rem;
}

.eyebrow {
  margin-bottom: 0.8rem;
}

.iconPlayPause {
  width: 2rem;
  height: 2rem;
  color: $color-white;
}
