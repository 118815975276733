@import '/styles/base.scss';

.spotlight {
  margin: 0 (-$margin-sm);

  @include md {
    margin: 0 (-$margin-md);
  }

  @include lg {
    margin: 0;
  }
}
